import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getDefaultSellerBanner(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/get_default_seller_banner', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getListSellerBanner(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/get_list_seller_banner', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getSellerBanner(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/get_seller_banner', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addSellerBanner(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/add_seller_banner', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editSellerBanner(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/edit_seller_banner', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteSellerBanner(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/delete_seller_banner', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    sellerBannerOptions(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/seller_banner_options', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    uploadImageSellerBanner(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/cms/upload_image_seller_banner/${params.id}`, params.data, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
