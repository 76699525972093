import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { t, t2 } from '@/@core/libs/i18n/utils'

export default function tableSellerBanner() {
  // Use toast
  const toast = useToast()

  const refSellerBannerTable = ref(null)

  const msgList = {
    errorfetch: t2('Error fetching {module} list', { module: t('SellerBanner') }),
    name: `${t('Name')} » ${t('ID')}`,
    image: `${t('Image')}`,
    status: `${t('Status')} » ${t('Sort')}`,
    actions: t('Actions'),
  }

  // Table Handlers
  const tableColumns = [
    { key: 'name', sortable: true, label: msgList.name },
    { key: 'image', sortable: false, label: msgList.image },
    { key: 'status', sortable: false, label: msgList.status },
    { key: 'actions', label: msgList.actions },
  ]

  const perPage = ref(10)
  const totalSellerBanners = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [2, 5, 10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('sort')
  const isSortDirDesc = ref(false)
  const locale = ref('th')

  const dataMeta = computed(() => {
    const localItemsCount = refSellerBannerTable.value ? refSellerBannerTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalSellerBanners.value,
    }
  })

  const refetchData = () => {
    refSellerBannerTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, locale], () => {
    refetchData()
  })

  const getListSellerBanner = (ctx, callback) => {
    const params = {
      q: searchQuery.value,
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      language: locale.value,
    }
    store
      .dispatch('store-seller-banner/getListSellerBanner', params)
      .then(response => {
        const { list, total } = response.data
        callback(list)
        totalSellerBanners.value = total
        localStorage.setItem('filterSellerBanner', JSON.stringify(params))
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: msgList.errorfetch,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        },
        {
          position: 'bottom-right',
        })
      })
  }

  const isStatusVariant = isstatus => {
    if (isstatus === 'active') return 'success'
    if (isstatus === 'inactive') return 'danger'
    return 'danger'
  }

  const isStatusToText = isstatus => {
    if (isstatus === 'active') return 'Active'
    if (isstatus === 'inactive') return 'Inactive'
    return ''
  }
  const textFirstUpper = text => text.slice(0, 1).toUpperCase() + text.slice(1, text.length)

  return {
    getListSellerBanner,
    tableColumns,
    perPage,
    currentPage,
    totalSellerBanners,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    locale,
    refSellerBannerTable,

    refetchData,
    isStatusVariant,
    isStatusToText,
    textFirstUpper,
  }
}
